import React from 'react';
import './App.scss';
import { Provider } from 'react-redux';
import { store } from '../../state/Redux';
import ReceiptFetcher from '../ReceiptFetcher';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import greeningo from '../../assets/greeningo.png';
import appstore from '../../assets/appstore.png';
import playstore from '../../assets/playstore.png';

function App() {

  return (
      <Provider store={store}>
        <div className={'header'}>
            <div>
                <div className={'topBar'}>
                    <img src={greeningo} className={'logo'} alt={''}/>
                </div>
                <div className={'storeLogoContainer'}>
                    <a href="https://apps.apple.com/by/app/green-ingo/id1541345418" target="_blank">
                        <div className={'storeLogoWrapper'}>
                            <img src={appstore} className={'storeLogo'} alt={''}/>
                        </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.greeningo" target="_blank">
                        <div className={'storeLogoWrapper'}>
                            <img src={playstore} className={'storeLogo'} alt={''} />
                        </div>
                    </a>
                </div>
            </div>
            <div className={'content'}>
                <Router>
                    <Switch>
                        <Route path={'/bon/:uuid'} children={<ReceiptFetcher />} />
                    </Switch>
                </Router>
            </div>
        </div>
      </Provider>
  );
}

export default App;
