import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

export function initSentry() {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN_CLIENT,
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
    });
}

export function consoleLogSentry() {
    let msg = '';
    for (let i = 0; i < arguments.length; i++) {
        msg = msg + arguments[i].toString();
    }
    console.log(msg);
    Sentry.captureMessage(msg);
}

export function throwExceptionSentry(error, response = undefined) {
    if (response) {
        try {
            Sentry.withScope(function(scope) {
                response.text().then(txt => {
                    scope.setContext('response', {'url': response.url, 'status': response.status, 'statusText': response.statusText, 'Content-Type': response.headers.get('content-type'), 'Body': txt});
                    Sentry.captureException(error, scope);
                });
            });
        } catch (error) {
            Sentry.captureException(new Error('throwExceptionWithResponseScopeSentry(): ' + error.toString()));
        }
    } else {
        Sentry.captureException(error);
    }
}


