import React from 'react';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import ReceiptTseBlock from './ReceiptTseBlock';

export default function ReceiptWrapper(props) {
    const {receipt, children} = props;

    const styles = {
        greetingContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 20,
            marginTop: 20,
            width: '100%',
        },
        addressContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20,
        },
        pointContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
        },
        outerContainer: {
            display: 'flex',
            flex: 1,
            padding: '5%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: Colors.receiptCardBackground,
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: 10,
        },
        metaContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 20,
        },
        hintContainer: {
            marginBottom: 20,
            width: '100%',
        },
        headerText2: {
            fontSize: 25,
            fontWeight: 'bold',
            color: Colors.scannerOpacity,
        },
        headerText: {
            fontWeight: 'bold',
            color: Colors.scannerOpacity,
            marginTop: 10,
            fontSize: 30,
        },
        greeting: {
            fontSize: 30,
            color: Colors.scannerOpacity,
        },
        text: {
            fontSize: 15,
            color: Colors.scannerOpacity,
        },
    };

    return (
        <div style={styles.outerContainer}>
            <span style={styles.headerText}>
                {Strings.receiptHeader1}
            </span>
            <span style={styles.headerText2}>
                {Strings.receiptHeader2}
            </span>
            <div style={styles.addressContainer}>
                <div style={styles.textContainer}>
                    <span style={styles.text}>{receipt.street}</span>
                    <span style={styles.text}>
                        {receipt.postal_code} {receipt.city}
                    </span>
                    <span style={styles.text}>
                        {Strings.fon + receipt.phone}
                    </span>
                    <span style={styles.text}>
                        {Strings.fax + receipt.fax}
                    </span>
                    <span style={styles.text}>
                        {Strings.ustId + receipt.vat_number}
                    </span>
                </div>
            </div>
            <div style={styles.metaContainer}>
                <span style={styles.text}>
                    {Strings.registerNo + receipt.registerNo}
                </span>
                <span style={styles.text}>
                    {Strings.registerId + receipt.terminal_id}
                </span>
                <span style={styles.text}>
                    {Strings.personnelNo + receipt.personnel_nr}
                </span>
                <span style={styles.text}>
                    {Strings.saleStartedAt + receipt.startDateString}
                </span>
                <span style={styles.text}>
                    {Strings.paidAt + receipt.endDateString}
                </span>
                <span style={styles.text}>
                    {Strings.receiptNo + receipt.receipt_nr}
                </span>
            </div>
            {children}
            <div style={styles.hintContainer}>
                <span style={styles.text}>{Strings.euroPriceHint}</span>
            </div>
            <div style={styles.pointContainer}>
                <span style={styles.text}>
                    {Strings.loyaltyCardNumber + receipt.loyalty_card_number}
                </span>
            </div>
            <div style={styles.pointContainer}>
                <span style={styles.text}>
                    {Strings.minusPoints + receipt.minusPoints}
                </span>
            </div>
            <div style={styles.pointContainer}>
                <span style={styles.text}>
                    {Strings.plusPoints + receipt.plusPoints}
                </span>
            </div>
            <div style={styles.greetingContainer}>
                <span style={styles.greeting}>{receipt?.slogan}</span>
            </div>
            {receipt.tseSerialNo && <ReceiptTseBlock receipt={receipt} />}
        </div>
    );
}
