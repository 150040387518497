export default {
  tintColor: '#29211C',
  emptyListLight: 'rgba(41, 33, 28, 0.3)',
  placeholder: 'rgba(0, 0, 0, .4)',
  headerBackground: 'rgba(250, 250, 250, 1)',
  bottomTabNavBackground: 'rgba(250, 250, 250, 1)',
  switchBackground: 'rgba(166, 166, 166, 0.5)',
  cardBackground: 'rgba(250, 250, 250, 0.7)',
  inputBackground: 'rgba(250, 250, 250, 1)',
  receiptCardBackground: 'rgba(250, 250, 250, 1)',
  receiptDetailBackground: 'rgba(0, 0, 0, 0.1)',
  receiptBtnUnderlay: 'lightgray',
  receiptScrollViewBackground: '#29211C',
  transactionListBackground: 'rgba(250, 250, 250, 1)',
  transactionBtnUnderlay: 'lightgray',
  scanButtonColor: 'rgba(250, 250, 250, 1)',
  scrollViewBackground: '#E0E0E0',
  tabActive: '#35BB75',
  tabInactive: '#29211C',
  warning: '#ED2E26',
  modalOpacity: 'rgba(255, 255, 255, .5)',
  scannerOpacity: 'rgba(0, 0, 0, .7)',
  cardSeparator: 'rgba(166, 166, 166, 0.3)',
  badgeColor: '#35BB75',
  barcodeColor: 'white',
  barcodeBackgroundDark: '#29211C',
  switchThumbColor: 'white',
  profileBtnTint: 'white',
};
