export default {
  // APP FRONT END
  // RECEIPT FIELDS
  payment: 'Zahlung',
  paymentKindCash: 'Bar',
  paymentKindCashCard: 'EC Karte',
  paymentKindPaymentCard: 'Guthabenkarte',
  paymentCardCharge: 'Guthabenkartenaufladung',
  loyaltyCardNumber: 'Kartennummer: ',
  minusPoints: 'Ausgegebene Punkte: ',
  plusPoints: 'Erhaltene Punkte: ',
  chargeText: 'Eingezahlt: ',
  euroPriceHint: 'Alle Preise in Euro.',
  sloganFallback: 'Bis bald im Sparredies!',
  receiptHeader1: 'Bäckerei Sparre',
  receiptHeader2: 'GmbH & Co. KG',
  fon: 'Fon: ',
  fax: 'Fax: ',
  ustId: 'USt.ID: ',
  registerNo: 'Kasse-Nr. : ',
  registerId: 'Kasse-ID : ',
  personnelNo: 'Personal-Nr. : ',
  saleStartedAt: 'Vorgangsbeginn : ',
  paidAt: 'Vorgangsende : ',
  receiptNo: 'BNr. : # ',
  netto: 'Netto',
  brutto: 'Brutto',
  sum: 'Sum',
  summe: 'Summe',
  retoure: 'Retoure',
  mwst: 'MwSt.',
  articleSeparator: '   x   ',
  tseTransaction: 'Transaktion:',
  tseSignature: 'Signatur:',
  publicKey: 'PublicKey:',
  tseSerialNo: 'Serien-Nr:',
  tseInformation: 'TSE Informationen',
};
