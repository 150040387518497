import React from 'react';
import {Dots} from 'react-activity';
import {renderToStaticMarkup} from 'react-dom/server';
import {throwExceptionSentry} from '../../services/SentryService';
import ReceiptWrapper from '../receipt/ReceiptWrapper';
import ReceiptCharge from '../receipt/ReceiptCharge';
import ReceiptSale from '../receipt/ReceiptSale';
import html2pdf from 'html2pdf.js';
import 'react-activity/dist/Dots.css';
import './ReceiptRenderer.scss';

export default function ReceiptRenderer(props) {

    const trianglesTop = [];
    const trianglesBottom = [];

    const exportToPdf = () => {
        const exportedReceipt = (
            <ReceiptWrapper receipt={props.receipt}>
                {props.receipt.receipt_kind === 'sale' && (
                    <ReceiptSale receipt={props.receipt} />
                )}
                {props.receipt.receipt_kind === 'charge' && (
                    <ReceiptCharge receipt={props.receipt} />
                )}
            </ReceiptWrapper>
        );
        try {
            html2pdf().from(renderToStaticMarkup(exportedReceipt), 'string').save(props.receipt.uuid + '.pdf');
        } catch (error) {
            throwExceptionSentry(new Error('exportToPdf(): ' + error.toString()));
        }
    }

    for (let i = 0; i < 500; i++) {
        trianglesTop.push(<div key={'top' + i} className={'triangleTop'}/>);
        trianglesBottom.push(<div key={'bottom' + i} className={'triangleBottom'}/>);
    }

    return props.receipt ? (
        <div className={'container'}>
            <div className={'scrollContainer'}>
                <div className={'receiptContainerWrapper'}>
                    <div className={'triangleContainer'}>
                        {trianglesTop}
                    </div>
                    <div className={'innerContainer'}>
                        <ReceiptWrapper receipt={props.receipt}>
                            {props.receipt.receipt_kind === 'sale' && (
                                <ReceiptSale receipt={props.receipt} />
                            )}
                            {props.receipt.receipt_kind === 'charge' && (
                                <ReceiptCharge receipt={props.receipt} />
                            )}
                        </ReceiptWrapper>
                    </div>
                    <div className={'triangleContainer'}>
                        {trianglesBottom}
                    </div>
                </div>
                <div className={'downloadBtn'} onClick={exportToPdf}>
                    <span className={'downloadText'}>Herunterladen</span>
                </div>
            </div>
        </div>
    ) : props.isError && (
        <div className={'errorMsgContainer'}>
            <span className={'errorMsg'}>{props.isError}</span>
            {props.fetchTimer < 4 && <>
                <Dots color={'gray'} size={25} className={'timerDots'}/>
                <span className={'timerMsg'}>Abruf {props.fetchTimer + 1} / 5</span>
            </>}
        </div>
    )
}
