import React from 'react';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';

export default function ReceiptCharge(props) {
    const {receipt} = props;

    const styles = {
        chargeContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 20,
            marginTop: 20,
            width: '100%',
        },
        chargeHeader: {
            fontSize: 22,
            color: Colors.scannerOpacity,
        },
        chargeText: {
            fontSize: 20,
            color: Colors.scannerOpacity,
        },
    };

    return (
        <>
            <div style={styles.chargeContainer}>
                <span style={styles.chargeHeader}>
                    {Strings.paymentCardCharge}
                </span>
            </div>
            <div style={styles.chargeContainer}>
                <span style={styles.chargeText}>
                    {Strings.chargeText + receipt.paymentSum} €
                </span>
            </div>
        </>
    );
}
