import { createStore } from 'redux';

export const SET_RECEIPT_REF = 'SET_RECEIPT_REF';
export const SET_RECEIPT = 'SET_RECEIPT';

const initialState = {
  ref: null,
  receipt: {},
};

const mainReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_RECEIPT_REF:
      newState.ref = action.value;
      return newState;
    case SET_RECEIPT:
      newState.receipt = action.value;
      return newState;
    default:
      return state;
  }
}

export let store = createStore(mainReducer);
