import React from 'react';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';

export default function ReceiptTseBlock(props) {
    const {receipt} = props;

    const styles = {
        tseHeaderContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
        },
        tseContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            marginBottom: 20,
            marginTop: 20,
            width: '100%',
            maxWidth: '100%',
        },
        column2: {
            display: 'flex',
            flex: 2,
            flexDirection: 'column',
        },
        column: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        text: {
            wordBreak: 'break-word',
            fontSize: 15,
            color: Colors.scannerOpacity,
        },
        row: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            paddingHorizontal: 10,
            paddingTop: 10,
        },
    };

    return (
        <>
            <div style={styles.tseContainer}>
                <div style={styles.tseHeaderContainer}>
                    <span style={styles.text}>{Strings.tseInformation}</span>
                </div>
                <div style={styles.row}>
                    <div style={styles.column}>
                        <span style={styles.text}>{Strings.tseTransaction}</span>
                    </div>
                    <div style={styles.column2}>
                        <span style={styles.text}>{receipt.tseTransactionNo}</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <div style={styles.column}>
                        <span style={styles.text}>{Strings.tseSignature}</span>
                    </div>
                    <div style={styles.column2}>
                        <span style={styles.text}>{receipt.tseSignature}</span>
                    </div>
                </div>
                <div style={styles.row}>
                    <div style={styles.column}>
                        <span style={styles.text}>{Strings.tseSerialNo}</span>
                    </div>
                    <div style={styles.column2}>
                        <span style={styles.text}>{receipt.tseSerialNo}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
