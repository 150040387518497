import React from 'react';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';

export default function ReceiptSale(props) {
    const {receipt} = props;

    const styles = {
        articleSeparatorContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 30,
        },
        itemRowPriceContainer: {
            display: 'flex',
        },
        articleCountContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 20,
        },
        itemRowNameContainer: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1
        },
        paymentContainer: {
            width: '90%',
            paddingLeft: '10%',
        },
        itemContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingHorizontal: '5%',
            paddingBottom: 20,
        },
        taxContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20,
            marginBottom: 20,
            width: '100%',
        },
        firstTaxItem: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: 40,
        },
        paymentRow: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        taxItem: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: 40,
            marginLeft: 20
        },
        itemRow: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        taxRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        text: {
            fontSize: 15,
            color: Colors.scannerOpacity,
        },
    };

    return (
        <>
            <div style={styles.itemContainer}>
                {receipt?.item_rows?.map((item, key) => (
                    <div key={'itemRow' + key} style={styles.itemRow}>
                        <div style={styles.articleCountContainer}>
                            <span key={'quantity' + key} style={styles.text}>
                                {item.quantity}
                            </span>
                        </div>
                        <div style={styles.articleSeparatorContainer}>
                            <span style={styles.text}>{Strings.articleSeparator}</span>
                        </div>
                        <div style={styles.itemRowNameContainer}>
                            <span key={'itemname' + key} style={styles.text}>
                            {item.itemName}
                        </span>
                        </div>
                        <div style={styles.itemRowPriceContainer}>
                            <span key={'tax' + key} style={styles.text}>
                                {item.totalAmountString + '€'}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div style={styles.paymentContainer}>
                <div style={styles.paymentRow}>
                    <span style={styles.text}>{Strings.summe}</span>
                    <span style={styles.text}>
                        {receipt.paymentAmount + '€'}
                    </span>
                </div>
                {receipt.paymentReturn !== '0' && (
                    <div style={styles.paymentRow}>
                        <span style={styles.text}>{Strings.retoure}</span>
                        <span style={styles.text}>
                            {receipt.paymentReturn + '€'}
                        </span>
                    </div>
                )}
                <div style={styles.paymentRow}>
                    <span style={styles.text}>{Strings.payment}</span>
                </div>
                <div style={styles.paymentRow}>
                    <span style={styles.text}>{receipt.paymentKind}</span>
                    <span style={styles.text}>
                        {receipt.paymentSum + '€'}
                    </span>
                </div>
            </div>
            <div style={styles.taxContainer}>
                <div style={styles.taxRow}>
                    <div style={styles.firstTaxItem}>
                        <span style={styles.text}>{Strings.mwst}</span>
                    </div>
                    <div style={styles.taxItem}>
                        <span style={styles.text}> </span>
                    </div>
                    <div style={styles.taxItem}>
                        <span style={styles.text}>{Strings.netto}</span>
                    </div>
                    <div style={styles.taxItem}>
                        <span style={styles.text}>{Strings.brutto}</span>
                    </div>
                </div>
                {receipt.taxValues.map((taxObj, i) => (
                    <div key={'taxObj' + i} style={styles.taxRow}>
                        <div style={styles.firstTaxItem}>
                            <span style={styles.text}>{taxObj.rate}</span>
                        </div>
                        <div style={styles.taxItem}>
                            <span style={styles.text}>{taxObj.sum}</span>
                        </div>
                        <div style={styles.taxItem}>
                            <span style={styles.text}>{taxObj.netto}</span>
                        </div>
                        <div style={styles.taxItem}>
                            <span style={styles.text}>{taxObj.brutto}</span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
