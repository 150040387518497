import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {consoleLogSentry, throwExceptionSentry} from '../services/SentryService';
import ReceiptRenderer from './receipt-renderer/ReceiptRenderer';
import {Dots} from 'react-activity';
import 'react-activity/dist/Dots.css';

export default function ReceiptToPdf() {

    const [receipt, setReceipt] = useState(undefined);
    const [isError, setIsError] = useState(false);
    const [fetchTimer, setFetchTimer] = useState(undefined);

    const uuid = useParams().uuid;

    useEffect(() => {
        if (fetchTimer === undefined) {
            fetchReceipt();
        } else if (fetchTimer < 4) {
            setTimeout(() => {
                fetchReceipt();
            }, 10000);
        } else {
            setIsError('Uups! Hier ist etwas schief gelaufen. Bitte aktualisieren Sie die Seite zu einem späteren Zeitpunkt.');
            consoleLogSentry('fetch timer stopped without fetching receipt');
        }
    }, [fetchTimer]);

    const fetchReceipt = () => {
        consoleLogSentry('start to fetch receipt for uuid ' + uuid);
        let response;
        try {
            fetch('/get/receipt/' + uuid, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                response = res;
                res.json().then(receipt => {
                    if (receipt && !receipt.error) {
                        receipt.uuid = uuid
                        if (fetchTimer !== undefined) {
                            consoleLogSentry('stopped fetch timer at ' + fetchTimer + 1 + ' with successfully fetched receipt');
                        }
                        setIsError(false);
                        setReceipt(receipt);
                    } else {
                        switch (receipt.error) {
                            case 'no receipt fetched':
                                setIsError('Ihr Bon wird übertragen und steht Ihnen gleich zur Verfügung.');
                                break;
                            case 'error in auth request':
                                setIsError('Uups! Hier ist etwas schief gelaufen. Bitte aktualisieren Sie die Seite zu einem späteren Zeitpunkt.');
                                break;
                            case 'no token fetched':
                                setIsError('Uups! Hier ist etwas schief gelaufen. Bitte aktualisieren Sie die Seite zu einem späteren Zeitpunkt.');
                                break;
                            default:
                                setIsError('Uups! Hier ist etwas schief gelaufen. Bitte aktualisieren Sie die Seite zu einem späteren Zeitpunkt.');
                        }
                        if (fetchTimer === undefined && receipt.error === 'no receipt fetched') {
                            setFetchTimer(0);
                        } else {
                            setFetchTimer(fetchTimer + 1);
                        }
                    }
                });
            });
        } catch (error) {
            throwExceptionSentry('' + error.toString());
        }
    };

    return !receipt && !isError ? <Dots color={'white'} size={30} className={'timerDots'}/> : <ReceiptRenderer receipt={receipt} isError={isError} fetchTimer={fetchTimer} />;
}
